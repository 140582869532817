
.landing-page {
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
   background-color: #f8198d;
   position: relative;
   z-index: 1;
   height: 100vh;
}

.full-main-page-con{
  height: 100vh;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

 .carousel .slide img {
  max-height: 800px;
  width: 100%;
}

.description {
  text-align: center;
  padding: 20px;
}

.description h1 {
  font-size: 1.5rem;
}

.description p {
  font-size: 1rem;
  margin-top: 20px;
}

.description button{
  font-size: 1rem;
}

.slider-container1 {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 0px;


}

 .slider-item {
  position: relative;
}

 .slider-image {
  width: 100%;

}

 .description-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
   padding: 20px;
  text-align: center;
}

 .description-overlay h1 {
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 3.5em;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;
}

.main-heading-title span {
  margin-left: 6px;
}

.dynamic-text1 {
  color: #f8198d;
}

.description-overlay p {
  margin-bottom: 10px;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.description-overlay button {
  font-size: 1.1rem;
  padding: 10px 20px;
  margin: 30px;
  text-align: center;
 border-radius: 20px;
 border-style: none;
 outline: none;
 width: 200px;


}
.about-h,
.contact-h {
  background-color: #f8198d;
  color: #fff;
}


.image-wrapper1 {
  position: relative;

}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0px);
}







/* Services.css */

/* Style the entire services section */
.services {
  background-color: #f7f7f7;
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

/* Style the heading */
.services h2 {
  font-size: 45px;
  color: #333;
  margin-top: 30px;
}

/* Style the container for service cards */
.service-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;

}

/* Style individual service cards */
/* index.css */
.service-card {
  width: 300px;
  height: 280px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 20px;
  padding: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

 }

.service-card:first-child {
  transform: rotate(0deg);
  background-color: #ffff;
  color: black;
  margin: 10px;
}

.service-card:hover {
  transform: rotate(0deg);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

}

.service-card:not(:first-child):hover {
  transform: rotate(15deg);
}

/* Add margin to the bottom of the third card in each row */
.service-card:nth-child(3n) {
margin-bottom: 90px;
}



 .service-icon {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 10px;
}

 .service-card h3 {
  font-size: 20px;
  margin: 10px;
}

 .service-card p {
  font-size: 16px;

  margin-bottom: 15px;
}

 .service-card button {
  background-color: #f8198d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

 .service-card button:hover {
  background-color: #0056b3;
}


.web-icon {
  width: 100px;
  height: 100px;
}


.service-pa{
  width: 1200px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 60px;
  align-items: center;
  margin-top: 50px;
}






.service-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Place the background behind the service container */
  pointer-events: none; /* Allow clicks to pass through the background */
  overflow: hidden;
}

/* Style the individual background images */
.background-image {
  position: absolute;
  animation: flowAnimation 15s linear infinite;
  opacity: 0.5;
}

/* Define the animation for the background images */
@keyframes flowAnimation {
  0% {
    transform: translate(-20%, -20%) scale(0.5);
  }
  25% {
    transform: translate(20%, 20%) scale(0.5);
  }
  50% {
    transform: translate(40%, -20%) scale(0.5);
  }
  75% {
    transform: translate(-20%, 40%) scale(0.5);
  }
  100% {
    transform: translate(20%, -20%) scale(0.5);
  }
}



/* Apply styles to the container */
.widget-categories-thumb {
background-repeat: no-repeat;
color: #000;
padding: 80px 0 80px;
margin-top: 20px;

background-size: cover;
object-fit: cover;
}

/* Apply styles to the button within .widget-button */
.widget-button a {
padding: 13px 25px;
font-size: 17px;
font-weight: 500;
background: #f8198d;
color: #fff;
border-radius: 4px;
display: inline-block;
text-decoration: none; /* Remove underline from the link */
}

.widget-he3{
color: #000;
font-size: 25px;
margin: 20px;
font-weight: 600;
}






@media screen and (max-width: 768px) {

  .full-main-page-con{
    height: 100vh;
  }

  .slider-container1 {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 0px;
  }

 .description-overlay h1 {
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5em;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;

}


.description-overlay p {
  margin-bottom: 10px;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.description-overlay button {
  font-size: 1.1rem;
  padding: 10px 20px;
  margin: 20px;
  text-align: center;
 border-radius: 20px;
 border-style: none;
 outline: none;
 width: 200px;
}




.services {
  background-color: #f7f7f7;
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

}

/* Style the heading */
.services h2 {
  font-size: 40px;
  color: #333;
  margin-top: 30px;
}



/* Style the container for service cards */
.service-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;

}

/* Style individual service cards */
/* index.css */
.service-card {
  width: 300px;
  height: 280px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 20px;
  padding: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

 }

.service-card:first-child {
  transform: rotate(0deg);
  background-color: #ffff;
  color: black;
  margin: 10px;
}

.service-card:hover {
  transform: rotate(0deg);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

}

.service-card:not(:first-child):hover {
  transform: rotate(15deg);
}

/* Add margin to the bottom of the third card in each row */
.service-card:nth-child(3n) {
margin-bottom: 90px;
}



 .service-icon {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 10px;
}

 .service-card h3 {
  font-size: 20px;
  margin: 10px;
}

 .service-card p {
  font-size: 16px;

  margin-bottom: 15px;
}

 .service-card button {
  background-color: #f8198d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

 .service-card button:hover {
  background-color: #0056b3;
}


.web-icon {
  width: 100px;
  height: 100px;
}


.service-pa{
  width: 1200px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 60px;
  align-items: center;
  margin-top: 50px;
}






.service-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Place the background behind the service container */
  pointer-events: none; /* Allow clicks to pass through the background */
  overflow: hidden;
}

/* Style the individual background images */
.background-image {
  position: absolute;
  animation: flowAnimation 15s linear infinite;
  opacity: 0.5;
}

/* Define the animation for the background images */
@keyframes flowAnimation {
  0% {
    transform: translate(-20%, -20%) scale(0.5);
  }
  25% {
    transform: translate(20%, 20%) scale(0.5);
  }
  50% {
    transform: translate(40%, -20%) scale(0.5);
  }
  75% {
    transform: translate(-20%, 40%) scale(0.5);
  }
  100% {
    transform: translate(20%, -20%) scale(0.5);
  }
}



/* Apply styles to the container */
.widget-categories-thumb {
background-repeat: no-repeat;
color: #000;
padding: 80px 0 80px;
margin-top: 20px;

background-size: cover;
object-fit: cover;
}

/* Apply styles to the button within .widget-button */
.widget-button a {
padding: 13px 25px;
font-size: 17px;
font-weight: 500;
background: #f8198d;
color: #fff;
border-radius: 4px;
display: inline-block;
text-decoration: none; /* Remove underline from the link */
}

.widget-he3{
color: #000;
font-size: 25px;
margin: 20px;
font-weight: 600;
}





















}


@media screen and (min-width: 768px) and (max-width: 1023px) {


  .full-main-page-con{
    height: 100vh;
  }

  .slider-container1 {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 0px;
  }

 .description-overlay h1 {
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.5em;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;

}


.description-overlay p {
  margin-bottom: 10px;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.description-overlay button {
  font-size: 1.1rem;
  padding: 10px 20px;
  margin: 20px;
  text-align: center;
 border-radius: 20px;
 border-style: none;
 outline: none;
 width: 200px;
}

 }













 /* Small laptops and desktops */
@media screen and (min-width: 1024px) and (max-width: 1365px) {


  .full-main-page-con{
    height: 100vh;
  }

  .slider-container1 {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 0px;
  }

 .description-overlay h1 {
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.5em;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;

}


.description-overlay p {
  margin-bottom: 10px;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.description-overlay button {
  font-size: 1.1rem;
  padding: 10px 20px;
  margin: 20px;
  text-align: center;
 border-radius: 20px;
 border-style: none;
 outline: none;
 width: 200px;
}


}

/* Medium to large desktops */
@media screen and (min-width: 1366px) {

  .full-main-page-con{
    height: 100vh;
  }

  .slider-container1 {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 0px;
  }

 .description-overlay h1 {
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.5em;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;

}


.description-overlay p {
  margin-bottom: 10px;
  text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
  letter-spacing: 0.02em;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.description-overlay button {
  font-size: 1.1rem;
  padding: 10px 20px;
  margin: 20px;
  text-align: center;
 border-radius: 20px;
 border-style: none;
 outline: none;
 width: 200px;
}



}