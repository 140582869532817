/*.container-client{
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    margin-left: -50px;

}
.quote-cl {
    width: 200px;
    height: 100px;


}

.green{
    margin-left: 90px;
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 30px;
    text-align: center;
    align-items: center;

}

.what-c{
    margin-left: 90px;
    font-size: 30px;
    margin-bottom: 70px;
    text-align: center;
    align-items: center;

}

.img-client-cl{

  width: 90px;
  height: 80px;
  border-radius: 50%;

}

.container-cl {
    display: flex;
  flex-direction: column;
  margin-left: 90px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 20px;
}

.footer-client{
    display: flex;

}

.name-client-1{
    width: 300px;
    font-size: 22px;
    margin-left: 20px;
    margin-top: 10px;
}

.position-client {
    margin-left: 23px;

}
.stars-cl{
    background-color: darkgoldenrod;
}
.stars{
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.gold-star{
    width: 20px;
    height: 20px;
}

.cl-invite{
    display: flex;
    flex-direction: row;
    margin-bottom: -59px;
}



















.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }
  .img-circle {
    border-radius: 50%;
    vertical-align: middle;
    max-width: 90px;
    min-height: 90px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
  }
  #customer-testimonoals {
    margin-top: 6%;
  }
  .testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
     background: linear-gradient(100deg, #FF8066, #FF918D);
     padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #customer-testimonoals .item {
    text-align: center;
    padding: 50px;
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{
     background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  }*/



  .testimonials {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .miniTitle {
    text-align: center;
  }

  .sectionTitle {
    text-align: center;
  }

  .text-center {
    text-align: center;
  }

  .testimonial-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ececec;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.4);
    margin: 0 auto 17px;
    width: 800px;

    margin-bottom: 30px;
  }

  .miniTitle{
    font-size: 36px;
  }



  .testimonial-item img {

    max-width: 250px;
    min-height: 150px;
    margin: 0 auto 17px;
  }
