.client-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 40px;
}

.img-icon-client{
    gap: 40px;
   width: 700px;
   display: flex;

    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.client-1{
    width: 200px;
    height: 50px;
}

.client-p{
    font-size: 25px;
}

@media screen and (max-width: 767px) {
    .img-icon-client{
    gap: 40px;
    width: 600px;
    display: flex;

     text-align: center;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
  }
}