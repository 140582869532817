
.our-main-con {
    background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695795574/WhatsApp_Image_2023-09-27_at_11.48.58_AM_qnqs2i.jpg");
    background-size: cover;
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 300px;
    border-radius: 20px;
}

.Our-Work-h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
}

.our-con {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin-top: 40px;
}
.info-india {
    width: 100%;
    height: 500px;
    display: flex;
    margin-bottom: 180px;
    flex-direction: row;



}
.info-h-p {
   display: flex;
   flex-direction: column;
   margin-left: 0px;
   width: 900px;
   padding: 30px;
   gap: 10px;

}
.info-img {
    width: 750px;
    height: 600px;
margin-left: 15px;
    margin-right: 30px;
    border-radius: 20px;
    background-size: cover;
    border-width: 5px;
    border-style: solid;

}
.info-h {
    font-size: 28px;
    font-weight: 600;

}
.info-p{
     font-size: 20px;
     width: 600px;
     height: 100%;

}

.vision-img {
    width: 800px;
    height: 380px;
    border-radius: 20px;
    background-size: cover;
}
.info1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 500px;
    margin-bottom: 40px;


}

.info-h-p1 {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 900px;
    padding: 30px;

 }


 .info-img1 {
    width: 800px;
    height: 480px;
    border-radius: 20px;
    background-size: cover;
    border-width: 5px;
    border-style: solid;

}

.vision-h-p1 {
   display: flex;
   flex-direction: column;
}
.vision-img2 {
    width: 800px;
    height: 480px;
    border-radius: 20px;
    background-size: cover;
}
.talk-m1 {
    background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695834644/Footer-img2-1_p6ko9k.jpg");
    background-size: cover;
    width: 100%;

margin-top: 4200px;
}
.talk-h {

    margin-left: 90px;
      font-weight: 900;
      color: #f8198d;
      text-transform: uppercase;
      font-size: 3.5em;
      text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
      letter-spacing: 0.02em;
}
.p-talk {
    margin-bottom: 10px;
    text-shadow: rgba(0, 0, 0, 0.498039) 2px 2px 2px;
    letter-spacing: 0.02em;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    width: 50%;
    margin-left: 40px;
    padding-top: 20px;
    margin-left: 100px;
    margin-bottom: 20px;
}
.btn-s {
    color: #ffffff;
    background-color: #f8198d;
    margin-left: 100px;
    padding: 10px;
    border-radius: 12px;
    outline: none;
    border-style: none;
    font-size: 14px;

}
.link-btn{
    width: 180px;
    height: 50px;
    border-style: none;
background-color: #f8198d;
 border-radius: 10px;
font-weight: 600;
color: #fff;
font-size: 22px;
 cursor: pointer;
 text-align: center;

}
.tech-icons{
    display: flex;
    flex-direction: row;
    gap: 20px;


}

.icon-img-our{
    width: 50px;
    height: 50px;
}


.tech-icons {
    display: flex;
    
    animation: zoomAndMove 5s linear infinite alternate; /* Adjust animation duration as needed */
  }


  @keyframes zoomAndMove {
    0% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-10px) scale(1.1);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }

