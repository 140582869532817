 .main-footer{
    width: 100%;
    height: 55vh;
    background-blend-mode: overlay;
  background-color: burlywood;
    /*background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1697054675/contact-us_in0dau.jpg"),linear-gradient(180deg,#d5e0fa 0%,#ffffff 100%)!important;*/
}

 .M-cube-con{
   display: flex;
   flex-direction: row;
   width: 100%;
   height: 50vh;
   justify-content: flex-start;
   gap: 190px;
 }

.m-cube{

  display: flex;
  flex-direction: column;
  width: 200px;
  margin-left: 40px;

}




.cube-h{
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.cube-p{
    font-size: 14px;
    font-weight: 600;
    margin-left: 40px;
    margin-top: -20px;
    font-weight: 600;

}

.img-con-foot{

    flex-wrap: wrap;

}

.face-foot {

    margin-left: 20px;
    width: 30px;
    height: 30px;
}


.sms-foot-con {

    margin-left: 20px;
    margin-top: 25px;
     display: flex;
     gap: 20px;
}


.sms-foot {

    width: 30px;
    height: 30px;
}

.foot-contact {

    font-size: 16px;
    color: #000000;
    font-weight: 600;
}


.mark-c{

    width: 30px;
    height: 30px;
}
.span-headq{

    display: flex;

}
.num-foot{

    font-size: 20px;
    margin-left: 30px;
    margin-top: 5px;
}
.bihar-h{
    font-size: 18px;
    margin-left: 30px;
}
.add-foot{
    margin-left: 40px;
    margin-top: 20px;
    width: 300px;
}

ul li {

  padding-bottom: 0px;
   /* Add your desired margin here */
}
ul li a {
  text-decoration: none;
  color: #000;
}

ul li a:hover {
  color: red; /* If you want a different color on hover */
}

.copyright-foot{
    font-size: 16px;
    text-align: center;
    align-items: center;
    background-color: #212529;
    color: #ffffff;
    height: 14vh;
    padding-top: 40px;
}

 .corner-image {
    position: fixed;
    bottom: 0;
    right: 0;
    align-items: end;
    text-align: end;
  }

  ul li a {
    text-decoration: none;

  }


.whats-app-foot{
    width: 65px;
    height: 65px;
    position: fixed;
    bottom: 0;
    right: 0;
    align-items: end;
    text-align: end;
    margin-right: 130px;
    margin-bottom: 80px;

}
.ul-con{
  list-style: none;
  text-decoration: none;

}


.whats-app-foot {
    display: flex;

    animation: zoomAndMove 5s linear infinite alternate;
  }


  @keyframes zoomAndMove {
    0% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-10px) scale(1.1);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }



  @media screen and (max-width: 767px) {
    .main-footer{
      width: 100%;
      height: 100vh;
      background-blend-mode: overlay;
    background-color: burlywood;
   }

   .M-cube-con{
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 100vh;
     justify-content: flex-start;
     gap: 90px;
   }

  .m-cube{

    display: flex;
    flex-direction: column;
    width: 200px;
    margin-left: 40px;

  }




  .cube-h{
      color: #000;
      font-size: 25px;
      font-weight: 600;
      margin-left: 20px;
      margin-top: 20px;
      margin-bottom: 20px;

  }

  .cube-p{
      font-size: 14px;
      font-weight: 600;
      margin-left: 40px;
      margin-top: -20px;
      font-weight: 600;

  }

  .img-con-foot{

      flex-wrap: wrap;

  }

  .face-foot {

      margin-left: 20px;
      width: 30px;
      height: 30px;
  }

.links-foot{
  height: 100vh;
  margin-bottom: -260px;
  gap: 30px;
}

  .sms-foot-con {

      margin-left: 20px;
      margin-top: 25px;
       display: flex;
       gap: 20px;
  }


  .sms-foot {

      width: 30px;
      height: 30px;
  }

  .foot-contact {

      font-size: 16px;
      color: #000000;
      font-weight: 600;
  }


  .mark-c{

      width: 30px;
      height: 30px;
  }
  .span-headq{

      display: flex;

  }
  .num-foot{

      font-size: 20px;
      margin-left: 30px;
      margin-top: 5px;
  }
  .bihar-h{
      font-size: 18px;
      margin-left: 30px;
  }
  .add-foot{
      margin-left: 40px;
      margin-top: 20px;
      width: 300px;
  }

  ul li {

    padding-bottom: 0px;
   }

  ul li a {
    text-decoration: none;
    color: #000;
  }

  ul li a:hover {
    color: red; /* If you want a different color on hover */
  }

  .copyright-foot{
      font-size: 16px;
      text-align: center;
      align-items: flex-start;
      background-color: #212529;
      color: #ffffff;
      height: 14vh;
      display: flex;
      justify-content: flex-start;
      padding-top: 40px;
   }

   .corner-image {
      position: fixed;
      bottom: 0;
      right: 0;
      display: flex;

      align-items: flex-start;
      text-align: start;
    }

    ul li a {
      text-decoration: none;

    }


  .whats-app-foot{
      width: 65px;
      height: 65px;
      position: fixed;
      bottom: 0;
      right: 0;
      align-items: end;
      text-align: end;
      margin-right: 130px;
      margin-bottom: 80px;

  }
  .ul-con{
    list-style: none;
    text-decoration: none;

  }
  



  .whats-app-foot {

           width: 65px;
           height: 65px;
           margin-left: 10px;
      animation: zoomAndMove 5s linear infinite alternate;
    }


    @keyframes zoomAndMove {
      0% {
        transform: translateY(0) scale(1);
      }
      50% {
        transform: translateY(-10px) scale(1.1);
      }
      100% {
        transform: translateY(0) scale(1);
      }
    }

  }