
.we-offer-main{
    background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695645860/cta-bg-img.39434724a310b9e24e6c_q9nrly.jpg");
    width: 100%;
    height: 400px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}
.we-offer {
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    justify-content: flex-start;
    text-align: left;
}
.we-text {
    color: black;
    font-size: 40px;
    font-weight: 600;
    width: 500px;
    margin-top: 60px;
}
.we-para {
    color: darkolivegreen;
    font-size: 15px;
  margin-top: 15px;
  width: 500px;
}
.btn-R {
    color:white;
    background-color: #f8198d;
    border-radius: 5px;
    border-style: none;
    outline: none;
    width: 150px;
    height: 40px;
}
.we-img {
    width: 500px;
    height: 450px;
    margin-left: 120px;
    margin-bottom: 20px;
}


/* For screens up to 767px (Mobile Devices) */
@media screen and (max-width: 768px) {
    .we-offer-main{
        background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695645860/cta-bg-img.39434724a310b9e24e6c_q9nrly.jpg");
        width: 100%;
        height: 100vh;
        margin-top: 50px;
        display: flex;
        flex-direction: column;

    }
    .we-offer {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: flex-start;
        text-align: left;
    }
    .we-text {
        color: black;
        font-size: 25px;
        font-weight: 600;
        width: 400px;
        margin-top: 30px;
    }
    .we-para {
        color: darkolivegreen;
        font-size: 15px;
      margin-top: 15px;
      width: 350px;
    }
    .btn-R {
        color:white;
        background-color: #f8198d;
        border-radius: 5px;
        border-style: none;
        outline: none;
        width: 150px;
        height: 40px;
    }
    .we-img {
        width: 400px;
        height: 450px;
        margin-left: -10px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

   }


 @media screen and (min-width: 768px) and (max-width: 1023px) {
    .we-offer-main{
        background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695645860/cta-bg-img.39434724a310b9e24e6c_q9nrly.jpg");
        width: 100%;
        height: 100vh;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        margin-left: 70px;
    }
    .we-offer {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: flex-start;
        text-align: left;
    }
    .we-text {
        color: black;
        font-size: 35px;
        font-weight: 600;
        width: 500px;
        margin-top: 30px;
    }
    .we-para {
        color: darkolivegreen;
        font-size: 15px;
      margin-top: 15px;
      width: 500px;
    }
    .btn-R {
        color:white;
        background-color: #f8198d;
        border-radius: 5px;
        border-style: none;
        outline: none;
        width: 150px;
        height: 40px;
    }
    .we-img {
        width: 400px;
        height: 450px;
        margin-left: -10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
  }







  /* Small laptops and desktops */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .we-offer-main{
        background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695645860/cta-bg-img.39434724a310b9e24e6c_q9nrly.jpg");
        width: 100%;
        height: 100vh;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        margin-left: 70px;
    }
    .we-offer {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: flex-start;
        text-align: left;
    }
    .we-text {
        color: black;
        font-size: 35px;
        font-weight: 600;
        width: 500px;
        margin-top: 30px;
    }
    .we-para {
        color: darkolivegreen;
        font-size: 15px;
      margin-top: 15px;
      width: 500px;
    }
    .btn-R {
        color:white;
        background-color: #f8198d;
        border-radius: 5px;
        border-style: none;
        outline: none;
        width: 150px;
        height: 40px;
    }
    .we-img {
        width: 400px;
        height: 450px;
        margin-left: -10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
  }

  /* Medium to large desktops */
  @media screen and (min-width: 1366px) {
    .we-offer-main{
        background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695645860/cta-bg-img.39434724a310b9e24e6c_q9nrly.jpg");
        width: 100%;
        height: 100vh;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        margin-left: 70px;
    }
    .we-offer {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: flex-start;
        text-align: left;
    }
    .we-text {
        color: black;
        font-size: 35px;
        font-weight: 600;
        width: 500px;
        margin-top: 30px;
    }
    .we-para {
        color: darkolivegreen;
        font-size: 15px;
      margin-top: 15px;
      width: 500px;
    }
    .btn-R {
        color:white;
        background-color: #f8198d;
        border-radius: 5px;
        border-style: none;
        outline: none;
        width: 150px;
        height: 40px;
    }
    .we-img {
        width: 400px;
        height: 450px;
        margin-left: -10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
  }