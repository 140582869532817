
.main-con-team{
    display: flex;
     width: 100%;
    height: 100vh;
 text-align: center;
 align-items: center;

 }

 .small-con-team {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
   align-items: center;
   text-align: center;
 }

 .head-team-h1{
    font-size: 90;
    margin-top: -350px;
  color: #f8198d;
  font-weight: 800;
  text-align: center;
  align-items: center;

 }

 .p-team{
    font-size: 30px;
    font-weight: 500;
    color: #091a29;
    width: 600px;
 }


 .icon-t{
  width: 200px;
  height: 200px;
 }

 .my-team{
    width: 100%;
    height: 100vh;
    margin-top: -200px;
 }

 .h-team-my{
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 50px;
    color: black;
 }


 .h-team-my-1{
    font-size: 60px;
    color: #f8198d;
    font-weight: 700;
    text-align: center;
    align-items: center;
    margin-top: 20px;

 }


 .img-team{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(13, 35, 53, 0.8);
    transition: all linear 0.3s;
    opacity: 0;
 }
 .images-con{
    display: flex;
    flex-direction: row;
 }

 .team .row .card {
    height: 30rem;
    width: 25rem;
    background-color: #fff;
    text-align: center;
    margin: 1rem 1rem;
    position: relative;
    overflow: hidden;
    margin-left: 70px;
   background-repeat: no-repeat;
    -webkit-box-reflect: below 5px linear-gradient(transparent 70%, #0004);

    transition: 0.5s;
  }

  .team .row .card::before {
    background: #f8198d;
    top: -3rem;
    right: -4rem;
  }

  .team .row .card::before,
  .team .row .card::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    height: 13.5rem;
    width: 13.5rem;
    z-index: -1;
  }

  .team .row .card .image {
    margin: 1rem 0;
    padding-top: 4rem;
  }

  .team .row .card::after {
    background: #ccc;
    bottom: -3rem;
    left: -4rem;
  }

.img-raj {
    height: 13rem;
    width: 13rem;
    border-radius: 50%;
    border: .5rem solid #fff;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
    object-fit: cover;
}

.t-icon{
    width: 50px;
    height: 50px;
}
.icons{
    display: flex;
    justify-content: center;
 gap: 20px;
 margin-top: 30px;
}



.react-icons-team {
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  margin-top: 50px;
  gap: 20px; /* Adjust the gap as needed */
  justify-content: center; /* Center align icons */
}

.technology-icon {
  width: 120px; /* Set a fixed width for the icon container */
  text-align: center;
  animation: bubble 2s ease-in-out infinite; /* Adjust animation duration as needed */
position: relative;
}

.technology-icon img {
  width: 80px; /* Set a fixed width for the icon image */
  height: 80px; /* Set a fixed height for the icon image */
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}



.dark_blue {

    padding: 50px 0; /* Adjust the padding as needed */
    text-align: center;
     padding-right: 00px;
  }

  .dark_blue .call_title_3 {
    color: #fff;
  }

  .dark_blue .call_title_3 h2 {
    color: #ffff; /* Pink color for the title */
  }

  .dark_blue .qoute_btn_3 {
    background-color: #f8198d; /* Pink background color for the button */
    color: #fff; /* Text color for the button */
    padding: 10px 20px; /* Adjust the padding as needed */
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }

  .dark_blue .qoute_btn_3:hover {
    background-color: #e0177d; /* Pink hover color for the button */
  }




  @keyframes bubble {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .bubble-animation:nth-child(odd) {
    animation-delay: 0.5s; /* Add a delay to alternate icons */
  }

  .items-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 500px;
    height: 300px;
    background-color: #ccc;
    margin-left: 500px;
    border-radius: 20px;
  }
  .items-team{
     display: flex;
     flex-direction: column;
  }

  .theme_span{
    margin-top: -70px;
    font-size: 20px;
  }

  .apply_btn_3{
    width: 200px;
    height: 40px;
    outline: none;
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    text-decoration: none;
    color: #ffff;
    padding-top: 5px;
    align-items: center;
    border-radius: 4px;
    background-color: #f8198d;
  }