


.faq-container {
    font-family: Arial, sans-serif;
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
   margin-top: -100px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.5); /* Lighter shadow effect */
 margin-top: 100px;
  margin-bottom: 100px;


  }

  .faq-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 40px;
    padding-bottom: 50px;
  }

  .faq-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
    width: 100%;
  }

  .faq-item.open {
    background-color: #f0f0f0;
  }

  .faq-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
  }

  .faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .faq-answer {
    margin-top: 10px;
    display: none;
  }

  .faq-item.open .faq-answer {
    display: block;
  }





.faq-icon{
    width: 20px;
    height: 20px;
}