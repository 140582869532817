/* our-stats.css */

/* Styling for the OurStatsSection component */
.our-stats {
    background-color: #f9f9f9;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
  }

  .our-stats-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .our-stat-icon {
    font-size: 36px;
    margin-bottom: 10px;
  }

  .our-stat-info {
    font-size: 35px;
  }

  /* Media queries for responsive design */
  @media (max-width: 767px) {
    .our-stats-box {
      margin-bottom: 20px;
    }
  }
.our-stats-h2{
  align-items: center;
  text-align: center;
  margin-bottom: 70px;
  color: #000;
    font-size: 30px;
    font-weight: 600!important;
    text-transform: uppercase;
    line-height: 44px;
    letter-spacing: 4px;
}
.service-pa1{
   margin-top: -220px;
}