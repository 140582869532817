.main-contact {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/dofzu13gt/image/upload/v1695839605/contact_gmdn7h.jpg");
}
.contact-d {
   display: flex;
   flex-direction: column;
    width: 100%;
   padding: 30px;
   align-items: flex-end;
   padding: 30px;

}

.slider-image{
  width: 100%;
  height: 50vh;
  object-fit: cover;

}

.image-wrapper {
  position: relative;
}





.contact-h-d {
    font-size: 28px;
    font-weight: 600;
    margin-right: 60px;
}
.contact-k{
     font-size: 44px;
     width: 600px;



}


.contact-text{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 49px;

  color: #fff;
  padding-top: 150px;

}



.contact-p {
    font-size: 20px;
    width: 400px;
    height: 100%;
    margin-top: 40px;

}
.contact-main {
    display: flex;
    flex-direction: column;

}

.details-contact {
    display: flex;
     justify-content: space-between;
    gap: 80px;
     width: 80%;
    margin-top: -200px;
    margin-left: 150px;
    position: relative;
    top: -100px;
    height: 100%;
    text-align: center;

    border-radius: 10px;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: hotpink;
    margin-bottom: 20px;
}
.email {
    display: flex;
    flex-direction: column;
    color: #fff;
 }
.email-img {
    width: 50px;
    height: 50px;
    align-items: center;
    padding: 10px;
    margin-left: 40px;
}
.h-email {
    font-size: 20px;
   margin-right: 70px;
}

.email-r{
    font-size: 18px;
    margin-right: 60px;
}


.phone-con{
  display: flex;
  flex-direction: column;
  color: #fff;
}

.phone-img{
  width: 50px;
  height: 50px;
  align-items: center;
  padding: 10px;
  margin-left: 20px;
}

.h-phone {
  font-size: 20px;
 margin-right: 70px;
}

.p-phone{
  font-size: 18px;
  margin-right: 60px;
}





.location-con{
  display: flex;
  flex-direction: column;
  color: #fff;
}

.locate-img{
  width: 50px;
  height: 50px;
  align-items: center;
  padding: 10px;
  margin-left: 40px;
}

.h-locate {
  font-size: 20px;
 margin-right: 160px;
}

.locate-r{
  font-size: 18px;
  margin-right: 90px;
}




.contact-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    margin-left: 90px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  backdrop-filter: blur(5px); /* Apply a blur effect to the background */
  z-index:1000; /* Ensure the overlay is above everything else */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
   padding: 20px; /* Add padding to give it some size */
  width: 400px;
  z-index: 1001; /* Ensure the popup is on top of the overlay */
  /* Add your popup styles here */
}


.contact-form {

    padding: 20px;
    border-radius: 10px;
    margin-top: -120px;

    margin-left: 150px;
    width: 80%;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add shadow effect */
    background: rgba(255, 255, 255, 0.9);

  }

  .contact-head {
    font-size: 34px;
    margin-bottom: 10px;
    color: #000;

  }

  .para-form {
    font-size: 20px;
    margin-bottom: 20px;
    color: #525659;
  }


  label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
    color: #6f7a82;
  }
  .name-s {
    color: red;
  }


  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;

    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    border-width: 0px;
    height: 50px;

  }

  input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 15px;
    margin-left: 100px;
    padding-left: 10px;

  }

  .checkboxes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

   }

  label.checkbox {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 20px;

  }


  .blur-background {
    filter: blur(5px);
    pointer-events: none; /* Prevents interaction with blurred elements */

  }







.btn-contact-form{
  background-color: #f8198d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}







































* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.container {
    width: 100%;
    height: 100vh;
    background-color: slategrey;
    display: flex;
    align-items: center;
    justify-content: center;

}

.btn {
    padding: 10px 60px;
    background-color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;
    font-size: 22px;
    font-weight: 500px;
    border-radius: 30px;
}


.popup {
    width: 400px;
    background-color: #fff;
    border-radius: 6px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.1);
    text-align: center;
    padding: 0 30px 30px;
    color: #333;
    visibility: hidden;
    transition: transform 0.4s, top 0.4s;
}

.open-popup {
    visibility: visible;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);

}








.popup img {
    width: 100px;
    margin-top: -50px;
    border-radius: 50%;
    box-shadow: 1 2px 5px rgba(0, 0, 0, 0.2);
}


.popup h2 {
    font-size: 38px;
    font-weight: 500;
    margin: 30px 0 10px;
}

.popup button {
    width: 100%;
    margin-top: 50px;
    padding: 10px 0;
    background-color: #6fd649;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
 }
 .contact-us-conn{
  text-align: center;
  align-items: center;
 }