 .top-section {
    display: flex;
    align-items: center;
    position: relative;
    padding: 2px;
    background-color: burlywood;
  }


  .contact-info{
    display: flex;
    width: 100%;
    color: #181b31;

  }


  .top-contact {
    display: flex;
    align-items: center;
    margin-left: 20px;

  }

  .contact-info p {
    margin: 0;
  }

  .contact-info span {
    margin-right: 20px;

}


.contact-info span:hover {
    color: #181b31;
}


.social-media {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
   margin-left: 400px;

}

.social-media a {
   color: #000;
    font-size: 24px;
    margin-right: 10px;
    text-decoration: none;

  }

  .social-media a:hover {
   color: #181b31;
  }

  .consultancy-button button {
    background-color: #fff;
    color: #000;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 20px;

  }

  .consultancy-button button:hover {
    background-color: #f8198d;
   color: #fff;
  }

.face-icon{
  width: 20px;
  height: 20px;
}

.div-box-icon{
  border-width: 1px;
  border-color: #000;
  height: 35px;
  width: 35px;
  border-style: solid;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 9px;
  padding-bottom: 5px;
 margin: 5px;
 border-radius: 50%;
}